import React from "react"
import Layout from "@layout"
import { graphql } from "gatsby"
import Link from "@components/link"

import * as styles from "./styles.module.scss"

class PageNotFound extends React.Component {
  render() {
    return (
      <Layout
        useTriangleBackground={true}
        menu={this.props.data.menu}
        footer={this.props.data.footer}
      >
        <h1 style={{ "margin-top": "150px" }}>404, page not found!</h1>
        <p className={styles.link}>
          <Link to="/">Back to homepage.</Link>
        </p>
      </Layout>
    )
  }
}

export default PageNotFound

export const query = graphql`
  query PageNotFoundQuery {
    ...MegaMenu
    ...Footer
  }
`
